@import "../../styles/globals.scss";
@import "../../styles/mixins.scss";

a.close-button {
  border: 0;
  position: absolute;
  text-decoration: none;
  &:visited {
    text-decoration: none;
  }

  h3 {
    margin: 0;
  }

  @media (orientation: portrait) {
    top: $fluid-gutter-height;
    right: $fluid-gutter-height;
  }

  @media (orientation: landscape) {
    top: $fluid-gutter-width;
    right: 31.5vw;
  }
}
