@import "../../styles/globals.scss";
@import "../../styles/mixins.scss";

.circle-link-container:hover {
  .circle-link__link {
    margin-left: -1em;
    transform: translateX(1em);

    &.external-link {
      &::after {
       @include external-link-icon()
      }
    }
  }

  .circle-link__link::before {
    content: url("data:image/svg+xml,%3Csvg width='15px' height='15px' viewBox='0 0 102 102' xmlns='http://www.w3.org/2000/svg' %3E%3Ccircle cx='50' cy='50' r='50' /%3E%3C/svg%3E");
    margin-right: 0.4em;
    height: $paragraph-size;
    width: $paragraph-size;
  }
}

.external-link {
  @media (orientation: portrait) {
    &::after {
      @include external-link-icon()
    }
  }
}

.circle-link {
  box-sizing: border-box;
  color: currentColor;
  display: inline;
  font-family: $font-stack-basis-grotesque;
  font-size: $paragraph-size;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
}

.circle-link__link {
  display: inline-block;
  transition: transform 0.2s ease;
}
