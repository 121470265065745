@import "../../styles/globals.scss";

.content-container--expanded + .main-menu {
  @media (orientation: portrait) {
    display: none;
  }
}

.main-menu {
  overflow: hidden;
  margin: 0;
  padding: 0;


  @media (orientation: landscape) {
    flex-grow: 1;
  }

  @media (orientation: portrait) {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    transition: 0.2s ease;
    margin-top: 0;
    height: calc(50vh - (var(--vh-offset, 0px) / 2));
  }
}
