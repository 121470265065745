@import "../../styles/globals.scss";
@import "../../styles/mixins.scss";

.default-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (orientation: portrait) {
    flex: 1 1 50vh;
    height: calc(50vh - (var(--vh-offset, 0px) / 2));
  }

  @media (orientation: landscape) {
    flex: 1;
    height: 100vh;
  }
}
