@import "../../styles/globals.scss";

.about-image-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;
  max-width: 100%;
  overflow: hidden;

  height: 100vh;
}

.about-image {
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  flex: 2 1 auto;
  text-align: right;
  padding-top: $fluid-gutter-width;
  padding-right: $fluid-gutter-width;

  @media (orientation: portrait) {
    padding-top: $fluid-gutter-height;
    padding-right: $fluid-gutter-height;
  }

  img {
    max-width: 66%;
    max-height: 100%;
  }
}
