@import "../../styles/globals.scss";

.homepage-image-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 0;
  justify-content: center;
  flex: 0 999 auto;
  @media (orientation: portrait) {
    display: none;
  }
}

.homepage-image-grid-container {
  box-sizing: border-box;
  max-height: 100%;
  overflow: hidden;
  @media (orientation: portrait) {
    max-height: 25vh;
    padding: 0 $fluid-gutter-height;
  }

  @media (orientation: landscape) {
    padding: 0 $fluid-gutter-width;
  }
}

.homepage-image-row {
  max-height: 100%;
}

.homepage-image {
  object-fit: contain;
  width: 100%;

  @media (orientation: portrait) {
    max-height: 25vh;
  }
  max-height: 66vh;
}
