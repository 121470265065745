@import "../../styles/globals.scss";

// TODO: replace this with circle link

$circle-size: 0.5em;

.hover-link-container {
  cursor: pointer;
  font-size: $paragraph-size;
  font-style: normal;
  font-weight: 500;
  color: $black;
  transition: transform 0.2s ease;

  .circle {
    display: none;
  }

  @media (orientation: landscape) {
    &:hover {
      a {
        margin-left: -$circle-size;
        transform: translate(1em, 0px);
      }
      .circle {
        display: inline-block;
        height: $circle-size;
        width: $circle-size;
        transform: translateY(-0.15em);
      }
    }
  }
}
