@import "globals.scss";
@import "mixins.scss";

@font-face {
  font-family: "Leitura";
  src: url("../../public/fonts/leitura-roman_1-webfont.woff2") format("woff2"),
    url("../../public/fonts/leitura-roman_1-webfont.woff") format("woff");
}

@font-face {
  font-family: "Leitura Italic";
  src: url("../../public/fonts/leitura-italic_1-webfont.woff2") format("woff2"),
    url("../../public/fonts/leitura-italic_1-webfont.woff") format("woff");
}

@font-face {
  font-family: "Basis Grotesque";
  src: url("../../public/fonts/basisgrotesque-medium-webfont.woff2") format("woff2"),
    url("../../public/fonts/basisgrotesque-medium-webfont.woff") format("woff");
}

.basis-grotesque {
  font-family: $font-stack-basis-grotesque;
}

h1 {
  font-family: $font-stack-leitura;
  font-style: normal;
  font-weight: normal;
  // line-height: 40px;
  line-height: 1.25em;
  // @media (orientation: portrait) {
  //   font-size: 32px;
  // }
  // @media (orientation: landscape) {
  //   font-size: 34px;
  // }
  // font-size: calc(32px + (48 - 32) * ((100vw - 400px) / (1900 - 400)));
  font-size: calc(32px + (16) * ((100vw - 400px) / (1500)));
  &.indented {
    text-indent: 52px;
  }
}

h2 {
  font-family: $font-stack-basis-grotesque;
  font-style: normal;
  font-weight: 500;
  // font-size: calc(18px + (24 - 18) * ((100vw - 400px) / (1900 - 400)));
  font-size: calc(18px + (6) * ((100vw - 400px) / (1500)));
  // @media (orientation: portrait) {
  //   line-height: 0.9;
  // }
  // @media (orientation: landscape) {
  //   font-size: 21px;
  //   line-height: 25px;
  // }
}

h3 {
  font-family: $font-stack-basis-grotesque;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2em;
  // font-size: calc(15px + (18 - 15) * ((100vw - 400px) / (1900 - 400)));
  font-size: $h3-size;
  // line-height: 20px;
  // @media (orientation: portrait) {
  //   font-size: 15px;
  // }
  // @media (orientation: landscape) {
  //   font-size: 18px;
  // }
}

h4 {
  font-family: $font-stack-leitura-italic;
  // font-style: italic;
  font-weight: normal;
  // font-size: 16px;
  // line-height: 20px;
  line-height: 1.2em;
  font-size: calc(16px + (16 - 16) * ((100vw - 400px) / (1900 - 400)));
}

p {
  @include paragraph;
}

p.italic {
  font-family: $font-stack-leitura-italic;
  font-weight: normal;
}

p.small {
  @include paragraph-small;
}
