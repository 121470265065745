$amber: #d7771a;
$apricot: #fdddc0;
$black: #000;
$blue: #539dcc;
$gray: #e5e5e5;
$green: #00a560;
$off-white: #faf5eb;

$one-sixth: (1/6);
$one-third: (1/3);
$one-fifth: (1/5);
$two-thirds: (2/3);
$two-fifths: (2/5);
$three-fifths: (3/5);
$four-fifths: (4/5);

$fluid-gutter-width: 1.5vw;
$fluid-gutter-height: 2vh;

$font-stack-leitura: Leitura, serif;
$font-stack-leitura-italic: "Leitura Italic", serif;
$font-stack-basis-grotesque: "Basis Grotesque", sans-serif;

$paragraph-size: calc(20px + (4) * ((100vw - 400px) / (1500)));
$paragraph-small-size: calc(16px + (2) * ((100vw - 400px) / (1500)));
$h3-size: calc(15px + 3 * ((100vw - 400px) / 1500));

$z-index: (
  menu-item-mobile-expanded: 200,
  sticky-header: 99,
  content-heading: 2,
);

$content-header-height-landscape: 70px;
