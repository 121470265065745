@import "../../styles/globals.scss";

.description {
  display: flex;
  flex-direction: row;
  background: $gray;
  padding-bottom: 0;
  width: 100%;
  max-width: 100%;
  padding: (2rem) $fluid-gutter-width ($fluid-gutter-width * 4)
    $fluid-gutter-width;
  justify-content: space-between;

  @media (orientation: portrait) {
    flex-direction: column;
    padding: (2rem) $fluid-gutter-height ($fluid-gutter-height * 4)
      $fluid-gutter-height;
  }
}

.description__heading {
  margin-top: 0;
}

.description__body {
  @media (orientation: landscape) {
    width: 33.3333vw;
  }
}
