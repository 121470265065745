@import "../../styles/globals.scss";
@import "../../styles/mixins.scss";

footer {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  font-family: $font-stack-basis-grotesque;
  justify-content: stretch;
  align-items: center;

  @media (orientation: portrait) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.footer__text {
  box-sizing: border-box;
  padding: $fluid-gutter-width;
  flex: 1 0 auto;

  @media (orientation: portrait) {
    padding: $fluid-gutter-height;
  }

  h3 {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    margin-right: 1em;
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }
}

.mailchimp-subscribe-form {
  box-sizing: border-box;
  display: flex;
  background: $off-white;
  justify-content: stetch;
  align-items: center;
  width: 100%;
  padding-right: $fluid-gutter-width;
  height: 100%;

  @media (orientation: portrait) {
    padding: $fluid-gutter-height;
  }

  input[type="email"] {
    box-sizing: border-box;
    font-family: $font-stack-leitura-italic;
    background: $off-white;
    margin-right: 1.5em;
    line-height: 1;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid black;
    // border: 1px solid red;
    width: 100%;
    outline: 0;
    max-width: 66vw;

    &:focus {
      background: $off-white;
    }
    @media (orientation: landscape) {
      max-width: 25vw;
    }
  }
}

.email-submit-button {
  background: $off-white;
  border: 0;
  outline: 0;
  cursor: pointer;
}
