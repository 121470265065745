@import "~normalize.css", "globals", "animations", "typography",
  "flexboxgrid.min.css";

html {
  // background: yellow;
  background: $off-white;
  box-sizing: border-box;
  font-family: $font-stack-leitura;
  * {
    box-sizing: border-box;
  }
}

html,
body {
  height: calc(100vh - var(--vh-offset, 0px));
  overflow: hidden;
  margin: 0;
}

body {
  background: $off-white;
  // border: 3px solid red;
}

#mount-point {
  // border: 3px solid green;
  // height: calc(100vh - var(--vh-offset, 0px));
}

.app {
  // border: 3px solid blue;
  height: calc(100vh - var(--vh-offset, 0px));
}

a,
a:visited {
  color: currentColor;
  text-decoration: none;
}

hr {
  border: 0.5px solid currentColor;
}

ul {
  list-style-type: none;
}

.circle {
  border-radius: 50%;
  background: $black;
}

.margin-0 {
  margin: 0;
}

// Overrides and hacks
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.container,
.container-fluid {
  padding: 0;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid green;
  -webkit-text-fill-color: $black;
  -webkit-box-shadow: 0 0 0px 1000px $off-white inset;
  transition: background-color 5000s ease-in-out 0s;
}
