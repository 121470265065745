@import "../../styles/globals.scss";
@import "../../styles/mixins.scss";


.menu-item {
  box-sizing: border-box;
  min-width: 100%; // needed for absolute position on mobile, may as well keep it

  @media (orientation: landscape) {
    
    height: calc(.20 * (100vh - var(--vh-offset, 0px)));
    

    &.expanded {
      display: flex;
      flex-direction: column;
      height: calc(100vh - var(--vh-offset, 0px));
      overflow-y: scroll;
    }
  }

  @media (orientation: portrait) {
    flex-grow: 1;

    &.expanded {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      display: flex; // needed for categories not to overflow off screen
      flex-direction: column;
      z-index: map-get($z-index, menu-item-mobile-expanded);
    }
  }
}

.menu-item--closed {
  cursor: pointer;
  padding: $fluid-gutter-width;
  @media (orientation: portrait) {
    padding: $fluid-gutter-height;
  }
}


.menu-item__sticky-header {
  background: inherit;
  box-sizing: border-box;
  padding: $fluid-gutter-width;
  @media (orientation: portrait) {
    padding: $fluid-gutter-height;
  }
}


.menu-item--music {
  // border: 4px dashed grey;
  background:#e7bd1a;
}

.menu-item--calendar {
  // border: 4px dashed grey;
  border-top: 0;
  background: $black;
  color: $off-white;

  // hack because i can't get this generated content to support currentColor
  &.circle-link-container:hover {
    .circle-link {
        .circle-link__link::before {
          margin-right: 0.4em;
          height: $paragraph-size;
          width: $paragraph-size;
          content: url("data:image/svg+xml,%3Csvg width='15px' height='15px' viewBox='0 0 102 102' xmlns='http://www.w3.org/2000/svg' %3E%3Ccircle fill='%23faf5eb' cx='50' cy='50' r='50' /%3E%3C/svg%3E");
        }
      
    }
  }

}

.menu-item--portfolio {
  background: $green;
}

.menu-item--journal {
  background: $blue;
}

.menu-item--about {
  background: $amber;
}
