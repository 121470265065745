@import "../../styles/globals.scss";

.content-heading {
  box-sizing: border-box;

  width: 100%;
  background: $off-white;
  padding: $fluid-gutter-width;

  @media (orientation: portrait) {
    padding: $fluid-gutter-height;
  }

  .title {
    margin: 0;
    font-family: $font-stack-leitura;
    line-height: 1;
  }

  .subtitle {
    margin: 0;
    margin-top: 0.6em;
    font-family: $font-stack-leitura-italic;
  }
}
