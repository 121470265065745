@import "../../styles/globals.scss";

.journal-list {
  box-sizing: border-box;
  list-style-type: none;
  margin: 0;
  margin-top: $fluid-gutter-width * 3;
  overflow-y: scroll;
  padding: $fluid-gutter-width;


  @media (orientation: portrait) {
    margin-top: $fluid-gutter-height * 3;
    padding: $fluid-gutter-height;
  }
}

.journal-list-item {
  box-sizing: border-box;
}

.journal-list-item__moving-block {
  display: inline-block;
  @media (orientation: landscape) {
    max-width: calc(30vw - 60px);
    &:hover {
      max-width: calc(30vw - 90px);
    }
  }
}
.menu-item.expanded .journal-list-item__moving-block {
  @media (orientation: landscape) {
    max-width: calc(50vw - 60px);
    &:hover {
      max-width: calc(50vw - 90px);
    }
  }
}

.journal-list-item__date {
  display: inline-block;
  font-family: $font-stack-basis-grotesque;
  font-size: $paragraph-small-size;
  line-height: 2;
}

.journal-list-item__title {
  display: inline-block;
  font-family: $font-stack-leitura;
  font-size: $paragraph-size;
  line-height: 1;
  margin-bottom: 2em;
}
