@import "../../styles/globals.scss";

.project-container {
  max-width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.project {
  &.container-fluid {
    padding-top: $fluid-gutter-width * 4;
  }
  animation: fadeIn 1s ease-out;
  animation-fill-mode: both;
  animation-delay: 0.2s;
  overflow: auto;

  @media (orientation: portrait) {
    &.container-fluid {
      padding-top: $fluid-gutter-height * 4;
    }
  }

  .secondary-info {
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
    margin-bottom: $fluid-gutter-width * 2;
  }
  .row {
    padding-left: $fluid-gutter-width;
    padding-right: $fluid-gutter-width;
    @media (orientation: portrait) {
      padding-left: $fluid-gutter-height;
      padding-right: $fluid-gutter-height;
    }
    &:last-child:not(.description) {
      padding-bottom: 60px; // categories filter height
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    // transform: translateY(30%);
  }
  80% {
    // transform: translateY(0%);
  }
  100% {
    opacity: 1;
  }
}

.youtube-embed-container {
  text-align: center;
  margin-bottom: 8rem;
}

.youtube-embed {
  width: 560px;
  height: 315px;
  max-width: 100%;
}
