@import "../../styles/globals.scss";

.music-list-wrapper {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between; // important
  overflow-y: scroll;
  overflow-x: hidden;
  line-height: 0;
  font-size: 0;
}

ul.music-list {
  box-sizing: border-box;
  font-size: $paragraph-size;
  list-style-type: none;
  margin: 0;
  padding: $fluid-gutter-width;
  padding-bottom: 0;
  flex: 1 1 auto;

  @media (orientation: portrait) {
    padding: $fluid-gutter-height;
  }

  li:first-child {
    margin-top: $fluid-gutter-width * 3;
    @media (orientation: portrait) {
      margin-top: $fluid-gutter-height * 3;
    }
  }

  li {
    box-sizing: border-box;

    @media (orientation: landscape) {
      max-width: calc(30vw - 60px);
      &:hover {
        max-width: calc(30vw - 90px);
      }
    }
  }

  li a {
    box-sizing: border-box;
    color: currentColor;
    font-family: $font-stack-leitura;
    font-size: $paragraph-size;
    line-height: (30/24);
    padding-bottom: 1.1em;
  }
}
