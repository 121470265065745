@import "../../styles/globals.scss";

.responsive-image {
  margin: 0;
  margin-bottom: 8rem;

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}
