@import "./globals.scss";

@mixin paragraph {
  font-family: $font-stack-leitura;
  font-size: $paragraph-size;
  line-height: 1.25;
  font-style: normal;
  font-weight: normal;
  margin: 0; // eek?
}

@mixin paragraph-small {
  @include paragraph();
  font-size: $paragraph-small-size;
}

@mixin button-style {
  @include paragraph-small();
  display: inline-block;
  text-align: center;

  background: transparent;
  font-family: $font-stack-basis-grotesque;
  border: 1px solid $black;
  border-radius: 2em;
  font-style: normal;
  font-weight: 500;

  vertical-align: middle;

  padding: 0.5em 1em 0.5em 1em;

  :hover {
    cursor: pointer;
  }
}

@mixin external-link-icon {
  display: inline-block;
  content: url(../../../public/externalUrl.svg);
  width: 0.8em;
  height: 0.8em;
  margin-left: 0.25em;
  // margin-bottom: 0.25em;
}
