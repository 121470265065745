@import "../../styles/globals.scss";

.hover-image {
  display: flex;
  flex: 0 1 100%;
  box-sizing: border-box;
  overflow: hidden;

  img {
    box-sizing: border-box;
    padding: $fluid-gutter-width;
    width: 50%;
    max-height: 100%;
    height: auto;
    object-fit: contain;

    @media (orientation: portrait) {
      display: none;
    }
  }

  &.Top {
    align-items: flex-start;
  }

  &.Middle {
    align-items: center;
  }

  &.Bottom {
    align-items: flex-end;
  }

  &.Left {
    justify-content: flex-start;
  }

  &.Center {
    justify-content: center;
  }

  &.Right {
    justify-content: flex-end;
  }
}
