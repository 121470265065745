@import "../../styles/globals.scss";
@import "../../styles/mixins.scss";

.categories-filter-row {
  @include paragraph-small;

  // flex: 0 1 auto;
  align-items: center;
  background: $apricot;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  font-family: $font-stack-basis-grotesque;
  justify-content: flex-start;
  line-height: 1;
  padding:  $fluid-gutter-height $fluid-gutter-width;
  // padding-left: $fluid-gutter-width;
  width: 100%;
  max-width: 50vw;

  @media (orientation: portrait) {
    padding-left: $fluid-gutter-height;
  }
}

.categories-filter-column {
  @include paragraph-small;

  align-items: flex-start;
  background: $apricot;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  font-family: $font-stack-basis-grotesque;
  justify-content: flex-start;
  padding-left: 0 $fluid-gutter-width; // TODO: wtf?
  width: 100%;

  @media (orientation: portrait) {
    padding-left: $fluid-gutter-height;
  }
}

.categories-filter-animated-container {
  z-index: -999;

  flex: 1 1 auto;

  box-sizing: border-box;
//   height: 0;
//   margin: 0;
//   transition: height 0.2s linear;
//   opacity: 0;

  &.expanded {
    z-index: 1;

    padding-bottom: $fluid-gutter-width;
//     height: auto;
//     opacity: 1;
    .category-selector {
      line-height: 1.5;
      margin: 0 0 0 $fluid-gutter-width;
    }
  }
}

.categories-toggle-button {
  align-items: center;
  background-color: $apricot;
  border: unset;
  color: $black;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  outline: none;
  padding: 1em $fluid-gutter-width 0 $fluid-gutter-width;
  text-align: left;
  width: 100%;

  @media (orientation: portrait) {
    padding: 1em $fluid-gutter-height 0 0;
  }
}


.categories-toggle-button__caret {
  transform: translateY(-0.25em);
  &.expanded {
    transform: scaleY(-1);
  }
}
