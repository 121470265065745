@import "../../styles/globals.scss";

.content-container {
  align-items: stretch;
  background: $off-white;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  height: calc(100vh - (var(--vh-offset, 0px)));

  justify-content: space-between;
  transition: width 0.2s ease;
  width: 50vw;
  // border: 4px dashed blue;

  overflow-y: scroll;

  @media (orientation: portrait) {
    width: 100vw;
    flex-basis: 0;
    flex-grow: 1;
  }

  a {
    text-decoration: underline;
  }

  footer a {
    border: none;
  }
}

.content-container--expanded {
  @media (orientation: landscape) {
    width: 70vw;
    padding-top: 0;
  }

  @media (orientation: portrait) {
    width: 100vw;
    height: 100vh;
    padding-top: 0;
  }
}
