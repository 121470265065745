@import "../../styles/globals.scss";

.journal-container {
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-heading {
    flex: 0 1 auto;
  }

  .journal-entry {
    overflow: scroll;
    padding: $fluid-gutter-width;
    padding-top: $fluid-gutter-width * 4;
    @media (orientation: portrait) {
      padding: $fluid-gutter-height;
      padding-top: $fluid-gutter-height * 4;
    }

    .responsive-image {
      margin-top: 1em;
      margin-bottom: 2em;
    }

    p:empty {
      display: none;
    }

    p {
      margin-bottom: 1em;
    }

    p + p {
      margin-top: 2em;
    }
  }
}
