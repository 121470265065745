.app {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (orientation: landscape) {
    flex-direction: row;
    justify-content: stretch;
  }

  @media (orientation: portrait) {
    flex-direction: column;
  }
}
