@import "../../styles/globals.scss";
@import "../../styles/mixins.scss";

.category-selector {
  cursor: pointer;
  margin-right: $fluid-gutter-width;

  @media (orientation: portrait) {
    line-height: 2;
    margin-right: $fluid-gutter-height;
  }

  input[type="checkbox"] {
    appearance: none;
    line-height: 0;
    height: 0;
    width: 0;
  }

  input[type="checkbox"]:checked + label .circle-check {
    background: $black;
    line-height: 0;
  }

  label {
      line-height: 2;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
  }

  .circle-check {
    box-sizing: border-box;
    display: inline-block;
    margin-right: 0.4em;
    border: 1px solid black;
    border-radius: 50%;
    height: 1em;
    width: 1em;
    line-height: 1;
  }
}
