@import "../../styles/globals.scss";
@import "../../styles/mixins.scss";

a.menu-button {
  color: currentColor;
  position: absolute;

  h3 {
    margin: 0;
  }

  @media (orientation: portrait) {
    top: $fluid-gutter-height;
    right: $fluid-gutter-height;
  }

  @media (orientation: landscape) {
    top: $fluid-gutter-width;
    right: $fluid-gutter-width;
  }
}
