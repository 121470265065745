@import "../../styles/globals.scss";

.about-page {
  box-sizing: border-box;
  overflow: auto;
  flex: 0 100%;

  @media (orientation: landscape) {
    max-width: 50vw;
  }

  padding: ($fluid-gutter-width * 8) $fluid-gutter-width
    ($fluid-gutter-width * 3) $fluid-gutter-width;

  @media (orientation: portrait) {
    padding: ($fluid-gutter-height * 8) $fluid-gutter-height
      ($fluid-gutter-height * 3) $fluid-gutter-height;
  }

  hr {
    &:first-child {
      margin-top: 0;
    }
    margin-top: $fluid-gutter-width * 2;
    margin-bottom: $fluid-gutter-width;
    @media (orientation: portrait) {
      margin-top: $fluid-gutter-height * 2;
      margin-bottom: $fluid-gutter-height;
    }
  }

  p {
    padding-right: $fluid-gutter-width;
    @media (orientation: portrait) {
      padding-right: $fluid-gutter-height;
      max-width: 30em;
    }
  }

  a {
    text-decoration: underline;
  }
}
